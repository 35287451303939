<template>
  <CommonPages id="home" title="Wallpaper" classs="home">
    <!-- <div v-if="movieCurrent" class="bg-float" :style="{
         backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #15151d 100%), url(${movieCurrent.thumbnails[0]})`,
         backgroundSize: 'cover',
         backgroundPosition: 'top',
         backgroundRepeat: 'no-repeat'
       }" /> -->
    <section class="movie-container pc_padding">
      <div class="page-relate-content">
        <div class="page-title">
          DailyFlicksGuide
        </div>
        <div v-if="movieCurrent" class="today">
          {{ formatDate(movieCurrent.recomDate) }}
        </div>
      </div>

      <section class="swiper">
        <swiper v-show="movieListRev && movieListRev.length>0" ref="mySwiper" class="swiper"
          :options="swiperOption" @transitionStart="transitionStart" @slideChange="onSlideChange"
          @tap="tapSlide">
          <swiper-slide v-for="(slide, index) in movieListRev" :key="index">
            <img :src="slide.thumbnails[0]" alt="">
          </swiper-slide>
        </swiper>
        <div v-if="deviceType != 'mobile'" slot="button-prev" class="swiper-button-prev" />
        <div v-if="deviceType != 'mobile'" slot="button-next" class="swiper-button-next" />
      </section>

      <AdComponent ref="ads-home_1" class="m-b-20 m-t-40 pc_padding" :ads="adsensConfig.home_1"
        :showDebug="showDebug" />

      <div v-if="movieCurrent" class="movie-content-continaer">
        <div class="movie-info-container">
          <div class="movie-info-row">
            <div class="movie-name">
              {{ movieCurrent.title }}
            </div>
            <div class="movie-tag-row">
              <div v-for="(tag,i) in movieCurrent.type" :key="`tag_${i}`" class="movie-tag-item">
                {{ tag }}
              </div>
            </div>
            <div class="movie-timeline-row">
              <div class="movie-release-item movie-release-date">
                <i class="icon-tag movie-timeline date" />
                {{ movieCurrent.releaseYear }}
              </div>
              <div class="movie-release-item movie-release-time">
                <i class="icon-tag movie-timeline time" />
                {{ movieCurrent.duration }}
              </div>
              <div class="movie-release-item movie-release-dirctor">
                <i class="icon-tag movie-timeline dirctor" />
                {{ movieCurrent.director }}
              </div>
            </div>
          </div>
        </div>

        <div class="movie-common-row movie-stars">
          <div class="movie-common-title">
            Stars
          </div>
          <div class="movie-common-content">
            <div class="movie-star-row">
              <div v-for="(stars,i) in movieCurrent.actors" :key="`star_${i}`"
                class="movie-star-item">
                {{ stars }}
              </div>
            </div>
          </div>
        </div>

        <div class="movie-score">
          <div class="movie-score-item imdb">
            <img src="@/assets/imdb.png" class="movie-score-img" alt="">
            {{ movieCurrent.imdbScore }}
          </div>
          <div class="movie-score-item imdb">
            <img src="@/assets/tomato.png" class="movie-score-img" alt="">
            {{ movieCurrent.tomatoesScore }}
          </div>
          <div class="movie-score-item imdb">
            <img src="@/assets/mov.png" class="movie-score-img" alt="">
            {{ movieCurrent.metacriticScore }}
          </div>
        </div>

        <AdComponent ref="adsRendered-home_2" class="m-b-20 m-t-20 pc_padding ad-width-100"
          :ads="adsensConfig.home_2" :showDebug="showDebug" />

        <div class="movie-common-row movie-story">
          <div class="movie-common-title">
            Storyline
          </div>
          <div class="movie-common-content" v-html="movieCurrent.briefDescription" />
        </div>

        <AdComponent ref="adsRendered-home_3" class="m-b-20 m-t-20 pc_padding ad-width-100"
          :ads="adsensConfig.home_3" :showDebug="showDebug" />

        <div class="movie-common-row movie-review">
          <div class="movie-common-title">
            Movie Review
          </div>
          <div class="movie-common-content">
            <div class="movie-review-row">
              <div v-for="(review,i) in movieCurrent.movieReviews" :key="`review_${i}`"
                class="movie-review-item">
                <img :src="review.avatar" class="review-avatar" alt="">
                <div class="review-item-content">
                  <div class="review-item-name">
                    {{ review.authorName }}
                  </div>
                  <div class="review-item-txt" v-html="review.review" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="movie-common-row movie-review">
          <div class="movie-common-title">
            Your Rating
          </div>
          <div class="movie-common-content">
            <div class="rating-row">
              <img v-for="(star, index) in maxStars" :key="index" :src="getStarSrc(index)"
                class="star-item" @mouseover="highlightStars(index)" @mouseleave="resetStars"
                @click="selectRating(index)">
            </div>
          </div>
        </div>

        <div class="movie-descript-common-row ">
          <div class="movie-descript-common-title">
            What DailyFlicksGuide.com offer？
          </div>
          <div class="movie-descript-common-content">
            Daily Movie Recommendations: Every day, we feature a handpicked classic movie, complete
            with a detailed synopsis, cast information, and intriguing trivia. Our selections range
            from golden age Hollywood masterpieces to international gems, ensuring a diverse and
            enriching viewing experience. <br> <br>
            In-Depth Reviews and Analysis: Our team of film experts and critics provide in-depth
            reviews and analyses of the featured movies, offering insights into their historical
            context, artistic significance, and cultural impact.
          </div>
        </div>
        <div class="movie-descript-common-row ">
          <div class="movie-descript-common-title">
            Why choose DailyFlicksGuide.com?
          </div>
          <div class="movie-descript-common-content">
            Expert Curation: Our team consists of seasoned film critics and historians dedicated to
            bringing you the best of classic cinema.<br> <br>
            Diverse Selection: We cover a wide range of films from different genres, countries, and
            time periods, ensuring a rich and varied movie-watching experience.<br> <br>
            Passion for Cinema: We are driven by a deep love for movies and a commitment to sharing
            the magic of classic films with our audience.
          </div>
        </div>

        <AdComponent ref="adsRendered-home_4" class="m-b-20 m-t-20 pc_padding ad-width-100"
          :ads="adsensConfig.home_4" :showDebug="showDebug" />

        <div class="movie-descript-common-row ">
          <div class="movie-descript-common-title">
            Early
          </div>
          <div class="movie-descript-common-content">
            <div class="movie-early-row">
              <div v-for="(movie, index) in movieList.slice(1)" :key="`moview_${index}`"
                class="movie-early-item cursor" :style="{
                  backgroundImage: `url(${movie.thumbnails[0]})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat'
                }" @click="gotoMovie(movie.dealTitle)">
                <div class="moview-early-shadow" />
                <div class="moview-early-date">
                  {{ formatDate(movie.recomDate) }}
                </div>
                <div class="moview-early-name">
                  {{ movie.title }}
                </div>
                <div class="moview-early-btn-row">
                  <div class="moview-early-btn cursor">
                    Read more
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="view-all-btn cursor" @click="goto('all')">
          View All Movies
        </div>
      </div>
    </section>
  </CommonPages>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import AdComponent from '@/pages/components/AdComponent.vue'
import CommonPages from '@/pages/components/pc_common.vue'
// import { Pagination, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import dayjs from 'dayjs'
// import 'swiper/css'
// import 'swiper/css/pagination'
// import 'swiper/css/navigation'

import '@/css/common.scss';
import '@/css/home.scss';

export default {
  name: 'Home',
  components: {
    AdComponent,
    CommonPages,
    Swiper,
    SwiperSlide
  },
  mixins: [common],
  data () {
    return {
      /////////
      movieList: [],
      movieListRev: [],
      movieCurrent: null,
      hasNext: true,
      page: 1,
      swiperOption: {
        direction: 'horizontal',
        slidesPerView: "auto",
        centeredSlides: true,// 居中的slide是否标记为active，默认是最左active,这样样式即可生效
        slideToClickedSlide: true,// 点击的slide会居中
        initialSlide: 9,//默认第9页
        // loop: true, // 开启无限循环
        // autoplay: 5000,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        reverseDirection: true,
        rtl: true,
        on: {
          // 该方法中的this都指代swiper本身
          tap () {
            console.log('点击的位置', this.activeIndex);
          }
        }

      },
      rating: 0, // 当前评分
      hoverRating: 0, // 鼠标悬停时的评分
      maxStars: 5, // 最大星星数
      // modules: [Pagination, Navigation]
      moviename: null
    };
  },
  metaInfo () {
    return {
      title: 'Daily Classic Movie Recommendations - DailyFlicksGuide.com',
      meta: [
        {
          name: 'description',
          content: this.moviename ? 'Discover a classic movie every day at DailyFlicksGuide.com. We provide detailed synopses, reviews, curated lists, and exclusive interviews to help you relive the golden age of cinema.' : 'Discover a classic movie every day at DailyFlicksGuide.com. We provide detailed synopses, reviews, curated lists, and exclusive interviews to help you relive the golden age of cinema.'
        },
      ],
    };
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    },
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "adsensConfig",
      'adPosConfig',
    ]),

  },
  created () {
    const { moviename } = this.$route.params;
    console.log(`moviename: ${moviename}`);
    this.moviename = moviename
    if (moviename) {
      this.getMovieDetail(moviename)
    } else {
      this.getMovieList()
    }
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    this.loadAdSenseScript();  // 重新加载广告脚本
    this.watchForIframeInsertion()

    console.log('Current Swiper instance object', this.swiper)
    // this.swiper.slideTo(3, 1000, false)
    // this.onScroll = this.debounce(this.onScroll.bind(this), 300); // 使用防抖包装滚动事件
    // window.addEventListener('scroll', this.onScroll);// 监听滚动事件
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
    // 组件销毁时移除滚动事件监听
    // window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    //客户端交互
    watchForIframeInsertion () {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
              this.setupIframeTracking(); // 在检测到广告 iframe 插入后，调用 setupIframeTracking
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },
    setupIframeTracking () {
      // let _this = this;
      console.log('ready to track iframe');
      const iframes = document.querySelectorAll('iframe');
      const tracker = new this.IframeTracker(this);
      iframes.forEach((iframe) => {
        tracker.addIframe(iframe, (iframeElement) => {
          console.log('Iframe 点击触发了!', iframeElement);
          const adContainer = iframeElement.closest('ins.adsbygoogle');

          if (adContainer) {
            // 获取父级 ins 元素的边界矩形
            const insRect = adContainer.getBoundingClientRect();

            // 计算父级 ins 元素相对于页面左上角的位置和高度
            const insX = insRect.left;
            const insY = insRect.top;
            const insHeight = insRect.height;

            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            let transformData = {
              insX: insX,
              insY: insY,
              insHeight: insHeight,
              windowWidth: windowWidth,
              windowHeight: windowHeight,
            }
            this.$adClick(JSON.stringify(transformData))
            tracker.trackEvent('ad_iframe_click', 'click', {
              'data-ad-slot': adContainer.dataset.adSlot,
            });
          }
        });
      });
    },
    IframeTracker: class {
      constructor(vues, resolution = 200) {
        this.vues = vues; // 保存 Vue 实例
        this.resolution = resolution;
        this.iframes = [];
        this.interval = null;
        this.activeIframe = null; // 存储最近被点击的iframe
        this.setupVisibilityTracking();
      }

      addIframe (element, callback) {
        const adContainer = element.closest('ins.adsbygoogle');
        const iframe = {
          element: element,
          callback: callback,
          hasTracked: false,
          adSlot: adContainer ? adContainer.dataset.adSlot : null,
        };
        this.iframes.push(iframe);

        if (!this.interval) {
          this.interval = setInterval(() => this.checkClick(), this.resolution);
        }
      }

      checkClick () {
        const activeElement = document.activeElement;
        if (activeElement) {
          this.iframes.forEach((iframe) => {
            if (activeElement === iframe.element && !iframe.hasTracked) {
              iframe.callback(iframe.element);
              iframe.hasTracked = true;
              this.activeIframe = iframe; // 更新最近被点击的iframe
            }
          });
        }
      }

      setupVisibilityTracking () {
        const hiddenProperty =
          'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null;
        const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');

        const onVisibilityChange = () => {
          if (!document[hiddenProperty]) {
            console.log('页面激活状态,返回页面?');
            this.activeIframe = null; // 重置最近被点击的iframe记录
          } else {
            console.log('页面非激活状态');

            if (this.activeIframe) {
              // 上报逻辑
              this.trackEvent('ad_iframe_click_leave', 'click', {
                'data-ad-slot': this.activeIframe.adSlot,
              });
            }
          }
        };

        this.iframeVisibilityTracker = onVisibilityChange;
        document.addEventListener(visibilityChangeEvent, this.iframeVisibilityTracker);
      }

      trackEvent (eventName, eventValue, dataAttributes) {
        // 事件追踪逻辑
        console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

        this.vues.$eventrack(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },
    //客户端交互
    gotoMovie (name) {
      this.$router.push({ path: `/${name}` });
    },
    getCurrentDate () {
      const currentDate = new Date();
      // 向前调整 7 天
      currentDate.setDate(currentDate.getDate() - 7);

      // 获取浏览器的时区偏移
      const timeZoneOffset = currentDate.getTimezoneOffset();

      // 调整时间为当前时区
      const adjustedDate = new Date(currentDate.getTime() - timeZoneOffset * 60 * 1000);

      // 格式化为 yyyymmdd 格式
      const year = adjustedDate.getUTCFullYear();
      const month = String(adjustedDate.getUTCMonth() + 1).padStart(2, '0');
      const day = String(adjustedDate.getUTCDate()).padStart(2, '0');

      return `${year}${month}${day}`;
    },
    formatDate (dateString) {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const dateOffsetString = dayjs(dateString, 'YYYYMMDD').add(7, 'day').format('YYYYMMDD');

      // 解析日期字符串
      // const year = dateString.slice(0, 4);
      const month = parseInt(dateOffsetString.slice(4, 6), 10) - 1; // 将月份转为从 0 开始的索引
      const day = dateOffsetString.slice(6, 8);

      // 格式化日期
      return `${months[month]} ${parseInt(day, 10)}`;
    },
    async getMovieDetail (moviename) {
      if (!this.hasNext) return
      try {
        const params = {
          title: moviename
        };

        const res = await this.$global.server.getMovieDetail(params);
        console.log(res)
        if (res.code == 200) {
          let data = res.data.earlyMovies
          let detail = res.data.movie
          document.title = detail.title
          this.movieList.push(...data);
          this.movieListRev = [...this.movieList].reverse(); // 逆向排列并赋值
          this.movieListRev.push(detail)
          this.movieCurrent = detail
          const cachedRating = localStorage.getItem(`rating_${this.movieCurrent.id}`);
          if (cachedRating) {
            this.rating = parseInt(cachedRating, 10);
            this.hoverRating = parseInt(cachedRating, 10);
          }
          this.displayAd('adsRendered-')
          this.swiper.slideTo(9, 1000, false)
        }

      } catch (err) {
        console.log(err)
      }
    },
    async getMovieList () {
      if (!this.hasNext) return
      try {
        const params = {
          pageNum: this.page,
          pageSize: 10,
          recomDate: this.getCurrentDate()
        };

        const res = await this.$global.server.getMovieList(params);
        console.log(res)
        if (res.code == 200) {
          let data = res.data.movies
          this.movieList.push(...data);
          this.movieListRev = [...this.movieList].reverse(); // 逆向排列并赋值
          this.movieCurrent = this.movieListRev[this.movieListRev.length - 1]
          const cachedRating = localStorage.getItem(`rating_${this.movieCurrent.id}`);
          if (cachedRating) {
            this.rating = parseInt(cachedRating, 10);
            this.hoverRating = parseInt(cachedRating, 10);
          }
          this.displayAd('adsRendered-')
          this.swiper.slideTo(9, 1000, false)
        }

      } catch (err) {
        console.log(err)
      }
    },
    // 获取星星图片
    getStarSrc (index) {
      if (index < this.hoverRating || (this.hoverRating === 0 && index < this.rating)) {
        return require('@/assets/star.png'); // 高亮星星图片
      } else {
        return require('@/assets/e-star.png'); // 空心星星图片
      }
    },
    // 鼠标悬停时高亮星星
    highlightStars (index) {
      this.hoverRating = index + 1;
    },
    // 鼠标移开时恢复星星状态
    resetStars () {
      this.hoverRating = 0;
    },
    // 点击时选择评分
    selectRating (index) {
      console.log('star');
      this.rating = index + 1;
      // 存储评分到 localStorage
      localStorage.setItem(`rating_${this.movieCurrent.id}`, this.rating);
      // this.$emit('update-rating', this.rating); // 触发事件传递选中的评分
    },
    transitionStart () {
      console.log('transitionStart');
      console.log(this.swiper);
      console.log(this.swiper.activeIndex);
    },
    onSlideChange () {
      console.log('onSlideChange');
      this.rating = 0
      this.hoverRating = 0
      this.movieCurrent = this.movieListRev[this.swiper.activeIndex]
      console.log(`rating_${this.movieCurrent.id}`);
      const cachedRating = localStorage.getItem(`rating_${this.movieCurrent.id}`);
      if (cachedRating) {
        this.rating = parseInt(cachedRating, 10);
        this.hoverRating = parseInt(cachedRating, 10);
      }
      console.log(this.swiper);
      console.log(this.swiper.activeIndex);
    },
    nextSlide () {
      this.currentSlide = (this.currentSlide + 1) % this.images.length;
      this.triggerAction();
    },
    prevSlide () {
      this.currentSlide =
        this.currentSlide === 0
          ? this.images.length - 1
          : this.currentSlide - 1;
      this.triggerAction();
    },
    tapSlide (e) {
      // 默认会$event对象
      console.log(e);
    },
    triggerAction () {
      console.log("Arrow clicked or mobile swipe triggered!");
    },
    loadLikeStatus () {
      // 从localStorage中读取当前图片的点赞状态
      const status = localStorage.getItem(`img_${this.selectImgInfo.id}_status`);
      if (status) {
        this.likeStatus = status;
      }
    },
    handleLike () {
      console.log(this.likeStatus);
      if (this.likeStatus !== 'like') {
        this.likeStatus = 'like'; // 改为点赞状态
        localStorage.setItem(`img_${this.selectImgInfo.id}_status`, 'like');
      } else {
        // 如果已经是点赞状态，再次点击恢复为点踩
        this.likeStatus = 'unlike';
        localStorage.setItem(`img_${this.selectImgInfo.id}_status`, 'unlike');
      }
    },
    //shuju
    async getImgInfo (id) {
      try {
        const res = await this.$global.server.getImgIdInfo({ id })
        console.log(res)
        this.selectImgInfo = res.data
        this.loadLikeStatus();

      } catch (err) {
        console.log(err)
      }
    },
    async getTagList () {
      try {
        const res = await this.$global.server.getTagList()
        console.log(res)
        this.tagList = res.data
      } catch (err) {
        console.log(err)
      }
    },
    async getImages () {
      try {
        const params = {
          pageNum: this.page,
          pageSize: 30,
        };

        // 只有当 this.tag 不为 null 时，才添加 tag 字段
        if (this.activeTag !== null) {
          params.tag = this.activeTag;
        }

        const res = await this.$global.server.getImages(params);
        console.log(res)
        console.log('iiiiiiiiii');
        if (res.code == 200) {
          let data = res.data.photos
          this.images.push(...data)
          this.hasNext = res.data.hasNext
          this.page++;
        }
      } catch (err) {
        console.log(err)
      }
    },
    seclecTag (tag) {
      window.location.href = `/tag/${tag}`
    },
    choosePic (id) {
      window.location.href = `/${id}`
    },
    //pubu
    loadItems () {
      if (this.isLoading) return; // 如果已经在加载，则不重复加载
      this.isLoading = true; // 设置加载状态

      // 模拟异步加载
      setTimeout(() => {
        const newItems = Array.from({ length: 20 }, (_, i) => ({
          src: `https://via.placeholder.com/${i + 1}00x${i + 2}00?text=Item+${i + 1 + this.page * 10}`,
          alt: `Item ${i + 1 + this.page * 10}`,
        }));
        this.items.push(...newItems);
        this.page++;
        this.isLoading = false; // 加载完成后重置加载状态
      }, 500);
    },
    // 防抖函数
    debounce (func, delay) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    onScroll () {
      // 获取文档的总高度
      const documentHeight = document.documentElement.scrollHeight;
      // 当前滚动的高度
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      // 视口的高度
      const windowHeight = window.innerHeight;

      // 当滚动距离接近底部2000px时触发
      if (scrollTop + windowHeight >= documentHeight - 2000) {
        console.log('加载');
        this.getImages();
      }
    },
    //广告相关start
    shouldInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.home.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldDetailInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.detail.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.loadAdWithDelay()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确 
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    loadAdWithDelay () {
      setTimeout(() => {
        this.displayAd('ads-');
      }, 500);
    },
    async displayAd (tag) {
      await this.$nextTick();

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key, ref]) => key.startsWith(tag) && ref !== undefined) // 排除 undefined 的 refs
        .flatMap(([, ref]) => ref); // 展开并获取所有元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(this.displayAd, 500); // 延迟再次尝试
        return;
      }

      console.log(adsElements); // 检查是否包含 <ins> 标签
      adsElements.forEach(ad => {
        console.log(ad); // 输出每个广告元素
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      });
    },
    //广告相关end

    //ciyi

    goto (name) {
      this.$router.push({ name: name });
    },

  }
};
</script>
